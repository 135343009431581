import React, { Fragment, useEffect, useState } from 'react'
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable
} from '@tanstack/react-table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown, faArrowUp, faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { Col, Row } from 'react-bootstrap'
import getPaginationControls from 'components/common/Utils/getPaginationControls'
import dollarPriceFormatter from 'formatters/dollar_price_formatter'
import moment from 'moment'

const columnHelper = createColumnHelper()

function CreditList({ creditMappings }) {
  const [data, setData] = useState([])
  const [filteredItems, setFilteredItems] = useState(creditMappings)
  const [searchText, setSearchText] = useState('')
  const [sorting, setSorting] = useState([])

  useEffect(() => {
    if (creditMappings) {
      setData(creditMappings)
    }
  }, [creditMappings])

  useEffect(() => {
    if (searchText) {
      const filteredItems = data.filter((item) => item.promotionCode.toLowerCase().includes(searchText.toLowerCase()))
      setFilteredItems(filteredItems)
    } else {
      setFilteredItems(data)
    }
  }, [searchText, data])

  const columns = [
    columnHelper.accessor('promotionCode', {
      header: () => 'Promotion Code'
    }),
    columnHelper.accessor('amount', {
      header: () => 'Amount',
      cell: (info) => `${dollarPriceFormatter(info.getValue())}`
    }),
    columnHelper.accessor('accountId', {
      header: () => 'Applied to AWS Account'
    }),
    columnHelper.display({
      id: 'balance',
      header: () => 'Balance',
      cell: ({ row }) => {
        const { amount, usedCredit } = row.original

        return `${dollarPriceFormatter(amount - (usedCredit || 0))}`
      }
    }),
    columnHelper.accessor('elementMap.element1Id', {
      header: () => 'Business Unit'
    }),
    columnHelper.accessor('elementMap.element2Id', {
      header: () => 'Department'
    }),
    columnHelper.accessor('elementMap.element3Id', {
      header: () => 'Portfolio'
    }),
    columnHelper.accessor('elementMap.element4Id', {
      header: () => 'Product'
    }),
    columnHelper.display({
      id: 'effectiveDates',
      header: () => 'Effective Dates',
      cell: ({ row }) => {
        const { startDate, endDate } = row.original

        return `${moment(startDate).format('MM-DD-YYYY')} - ${moment(endDate).format('MM-DD-YYYY')}`
      }
    })
  ]

  const table = useReactTable({
    data: filteredItems,
    columns,
    state: {
      sorting
    },
    onSortingChange: setSorting,
    getRowCanExpand: () => true,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getExpandedRowModel: getExpandedRowModel()
  })

  const onSearchKey = (event) => {
    const { value } = event.target
    setSearchText(value)
  }

  return (
    <div className="simple-card">
      <h1>Credit List</h1>
      <Row className="mb-2">
        <Col md={4}>
          <input
            type="text"
            value={searchText}
            className="form-control"
            placeholder="Search by Promotion Code or Financial Scope"
            onChange={onSearchKey}
          />
        </Col>
        <Col md={8}>{getPaginationControls(table)}</Col>
      </Row>
      <div className="table-responsive">
        <table className="table table-bordered">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                      <button
                        {...{
                          className: header.column.getCanSort()
                            ? 'd-flex justify-content-between align-items-center user-select-none'
                            : '',
                          onClick: header.column.getToggleSortingHandler()
                        }}
                        className="sorting-button"
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {{
                          asc: <FontAwesomeIcon className="ml-2" icon={faArrowUp} />,
                          desc: <FontAwesomeIcon className="ml-2" icon={faArrowDown} />
                        }[header.column.getIsSorted()] ?? null}
                      </button>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody>
            {table.getRowModel().rows.map((row) => (
              <Fragment key={row.id}>
                <tr>
                  {row.getVisibleCells().map((cell) => (
                    <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                  ))}
                </tr>
              </Fragment>
            ))}
          </tbody>
        </table>
      </div>
      {getPaginationControls(table)}
    </div>
  )
}

export default CreditList
