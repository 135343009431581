import { Card, Col, Row } from 'react-bootstrap'
import Select from 'react-select'
import { getFormatTimestamp } from '../helpers/getFormatTimestamp'
import { getInvoicePeriods } from '../helpers/getInvoicePeriods'
import { useContext, useEffect, useRef, useState } from 'react'
import dollarPriceFormatter from 'formatters/dollar_price_formatter'
import InvoiceForm from './InvoiceForm'
import LoadingSpinner from 'components/common/LoadingSpinner'
import createInvoiceUseCase from '../use-cases/create-invoice-use-case'
import AppContext from 'context/AppContext'
import getCreditsUseCase from '../use-cases/getCreditsUseCase'

const periodInvoiceOptions = getInvoicePeriods().map((period) => ({
  value: new Date(period.split('-')[0]).getMonth(),
  label: period
}))

const FPAndAInvoice = ({ actuals = [] }) => {
  const invoiceFormRef = useRef()
  const [items, setItems] = useState([])
  const [credits, setCredits] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [selectedPeriod, setSelectedPeriod] = useState(periodInvoiceOptions[0]?.value)
  const [totalData, setTotalData] = useState({
    usage: 0,
    tax: 0,
    total: 0
  })
  const { repoFactory, selectedYear } = useContext(AppContext)

  useEffect(() => {
    if (selectedPeriod && selectedYear) {
      fetchCreditsData({
        year: selectedYear,
        month: selectedPeriod + 1
      })
    }
  }, [selectedYear, selectedPeriod])

  const fetchCreditsData = ({ year, month }) => {
    setIsLoading(true)
    getCreditsUseCase(
      {
        year,
        month,
        token: localStorage.getItem('authToken')
      },
      {
        actualsRepo: repoFactory.actualsRepo(),
        observer: {
          errorGettingCredits: (e) => {
            setIsLoading(false)
            setCredits([])
          },
          receiveCredits: (response) => {
            setIsLoading(false)
            setCredits(response.creditsUsed)
          }
        }
      }
    )
  }

  useEffect(() => {
    filterActualsByPeriod(actuals, credits)
  }, [actuals, selectedPeriod, credits])

  const filterActualsByPeriod = (actuals, credits) => {
    const creditsMap = new Map(
      credits.map((credit) => [
        [
          credit.elementMap.element1Id,
          credit.elementMap.element2Id,
          credit.elementMap.element3Id,
          credit.elementMap.element4Id
        ].join('_'),
        credit.totalCredit
      ])
    )
    const actualsMap = {}
    actuals.forEach((actual) => {
      const { element1, element2, element3, element4, values } = actual

      const creditUsed = creditsMap.get([element1, element2, element3, element4].join('_'))

      if (actualsMap.hasOwnProperty(element4)) {
        actualsMap[element4].amount += values[`_${selectedPeriod}`]?.cost || 0
        actualsMap[element4].tax += values[`_${selectedPeriod}`]?.tax || 0
      } else {
        actualsMap[element4] = {
          element1: element1,
          element4: element4,
          dateRange: periodInvoiceOptions.find((option) => option.value === selectedPeriod)?.label,
          amount: values[`_${selectedPeriod}`]?.cost,
          credit: creditUsed,
          tax: values[`_${selectedPeriod}`]?.tax || 0
        }
      }
    })

    setItems(Object.values(actualsMap))
  }

  useEffect(() => {
    let usage = 0
    let tax = 0
    items.forEach((item) => {
      usage += item.amount || 0
      tax += item.tax || 0
    })

    setTotalData({
      usage,
      tax,
      total: usage + tax
    })
  }, [items])

  const getInvoiceData = () => {
    const invoiceForm = invoiceFormRef.current

    if (invoiceForm) {
      invoiceForm.submitForm()
      if (invoiceForm.isValid) {
        const { vendor, internal, purchaseOrder, invoiceNumber } = invoiceForm.values

        const breakdown = items.map((item) => ({
          credit: item.credit,
          amount: item.amount,
          dateRange: item.dateRange,
          element1: item.element1,
          element4: item.element4
        }))

        const { usage, credit, tax, total } = totalData

        return {
          breakdown,
          credit,
          internal,
          invoiceNumber,
          month: selectedPeriod,
          purchaseOrder,
          tax,
          total,
          type: '',
          usage,
          year: new Date().getFullYear(),
          vendor
        }
      }
    }

    return null
  }

  const createInvoice = async () => {
    const invoiceData = getInvoiceData()
    if (invoiceData) {
      setIsLoading(true)
      createInvoiceUseCase(
        {
          invoice: invoiceData
        },
        {
          monthCloseRepo: repoFactory.monthCloseRepo(),
          observer: {
            success: (response) => {
              const invoiceForm = invoiceFormRef.current

              if (invoiceForm) {
                invoiceForm.resetForm()
              }

              createCsvFile(invoiceData)
              setIsLoading(false)
            },
            error: () => {
              setIsLoading(false)
            }
          }
        }
      )
    }
  }

  const createCsvFile = (invoiceData) => {
    let csvContent = `
    Vendor, ${invoiceData.vendor},
    Invoice Number,${invoiceData.invoiceNumber},
    Invoice Date,${periodInvoiceOptions.find((option) => option.value === selectedPeriod).label},
    Purchase Order,${invoiceData.purchaseOrder},
    Internal,${invoiceData.internal},
    ,,
    Product,Billing Period,Applied Credit,Monthly Accrual,
    `

    invoiceData.breakdown.forEach((item) => {
      csvContent += `${item.element4},${item.dateRange},"${dollarPriceFormatter(item.credit)}","${dollarPriceFormatter(
        item.amount
      )}",
      `
    })

    const blob = new Blob([csvContent], { type: 'text/csv' })
    const url = URL.createObjectURL(blob)

    const filename = 'FP&A_' + getFormatTimestamp() + '.csv'

    const link = document.createElement('a')
    link.href = url
    link.download = filename
    link.click()
  }

  return (
    <>
      {isLoading && <LoadingSpinner />}
      <h1>FP&A Invoice</h1>

      <Row>
        <Col md={4}>
          <h1 className="mb-1">Period</h1>
          <Select
            classNamePrefix="select"
            placeholder="Date Period"
            options={periodInvoiceOptions}
            defaultValue={periodInvoiceOptions[0]}
            value={periodInvoiceOptions.find((option) => option.value === selectedPeriod)}
            onChange={(option) => setSelectedPeriod(option.value)}
            isClearable
          />
        </Col>
      </Row>

      <hr />

      <Card>
        <Card.Body>
          <InvoiceForm formEl={invoiceFormRef} />
        </Card.Body>
      </Card>

      {items?.length ? (
        <Card className="mt-4">
          <Card.Body>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Product</th>
                  <th>Accrual Period</th>
                  <th>Applied Credit</th>
                  <th>Monthly Accrual</th>
                </tr>
              </thead>
              <tbody>
                {items.map((item, index) => (
                  <tr key={`invoice-${index}`}>
                    <td>{item.element4}</td>
                    <td>{item.dateRange}</td>
                    <td>{dollarPriceFormatter(item.credit)}</td>
                    <td>{dollarPriceFormatter(item.amount)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="text-center">
              <button className="btn btn-primary btn-sm" onClick={createInvoice}>
                Create File
              </button>
            </div>
          </Card.Body>
        </Card>
      ) : null}
    </>
  )
}
export default FPAndAInvoice
