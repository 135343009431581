import { getRegionOptions } from 'config/Regions'
import { useFormik } from 'formik'
import { useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import Select from 'react-select'
import ERROR_MESSAGES from 'utils/errorMessages'

const validate = (values) => {
  const errors = {}
  const { region, account } = values

  if (!region) {
    errors.region = ERROR_MESSAGES.REQUIRED
  }

  if (!account) {
    errors.account = ERROR_MESSAGES.REQUIRED
  } else if (!/^[0-9]+$/.test(account)) {
    errors.account = ERROR_MESSAGES.NUMERIC
  } else if (account.length !== 12) {
    errors.account = 'Account number must be 12 digits'
  }

  return errors
}

const regionOptions = getRegionOptions()

const EnvironmentForm = ({ formEl, accountNumber, region, setIsEnableAmazonQTopics }) => {
  const formik = useFormik({
    initialValues: {
      region: region,
      account: accountNumber,
      isEnableAmazonQTopics: false
    },
    validate,
    validateOnMount: true,
    onSubmit: () => {
      formEl.current = formik
    }
  })

  useEffect(() => {
    formEl.current = formik
  }, [formik, formEl])

  useEffect(() => {
    formik.setFieldValue('region', region)
  }, [region])

  useEffect(() => {
    formik.setFieldValue('account', accountNumber)
  }, [accountNumber])

  return (
    <form onSubmit={formik.handleSubmit} autoComplete="off">
      <Row>
        <Col md={6}>
          <div className="mb-3">
            <label>Region</label>
            <Select
              name="region"
              classNamePrefix="select"
              options={regionOptions}
              value={regionOptions.find((option) => option.value === formik.values.region)}
              onChange={(option) => formik.setFieldValue('region', option?.value || '')}
              isClearable
            />
            {formik.touched.region && formik.errors.region ? (
              <div className="text-error">{formik.errors.region}</div>
            ) : null}
          </div>
        </Col>
        <Col md={6}>
          <div className="mb-3">
            <label>Account</label>
            <input
              name="account"
              className="form-control"
              placeholder="Account"
              value={formik.values.account}
              onChange={formik.handleChange}
            />
            {formik.touched.account && formik.errors.account ? (
              <div className="text-error">{formik.errors.account}</div>
            ) : null}
          </div>
        </Col>
        {/*<Col md={6}>*/}
        {/*  <div className="mb-3">*/}
        {/*    <div className="form-check">*/}
        {/*      <input*/}
        {/*        id="isEnableAmazonQTopics"*/}
        {/*        name="isEnableAmazonQTopics"*/}
        {/*        className="form-check-input"*/}
        {/*        type="checkbox"*/}
        {/*        onChange={(e) => {*/}
        {/*          formik.setFieldValue('isEnableAmazonQTopics', e.target.checked)*/}
        {/*          setIsEnableAmazonQTopics(e.target.checked)*/}
        {/*        }}*/}
        {/*        checked={formik.values.isEnableAmazonQTopics}*/}
        {/*      />*/}
        {/*      <label className="form-check-label" htmlFor="isEnableAmazonQTopics">*/}
        {/*        Enable Amazon Q Topics*/}
        {/*      </label>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</Col>*/}
      </Row>
    </form>
  )
}
export default EnvironmentForm
