import Select from 'react-select'
import { Card, Modal } from 'react-bootstrap'
import { useFormik } from 'formik'
import ERROR_MESSAGES from 'utils/errorMessages'

const validate = (values) => {
  const errors = {}
  if (!values.workload) {
    errors.workload = ERROR_MESSAGES.REQUIRED
  }
  return errors
}

function ClaimModal({ selectedRows = null, setSelectedRows, workloads = [], claim }) {
  const workloadOptions = workloads.map((workload) => ({ value: workload, label: workload }))

  const formik = useFormik({
    initialValues: {
      workload: ''
    },
    validate,
    validateOnMount: true,
    onSubmit: () => {}
  })

  const save = async () => {
    await formik.submitForm()

    if (formik.isValid) {
      const resources = selectedRows.map((row) => ({
        ...row,
        reference: formik.values.workload
      }))
      claim(resources)
    }
  }

  return (
    <Modal show={selectedRows} onHide={() => setSelectedRows(null)} centered>
      <Modal.Body>
        <h6>Claim Resource</h6>
        <Card className="section-gray mb-3">
          <Card.Body>
            <div className="mb-1" style={{ fontSize: '12px' }}>
              You are going to claim {selectedRows.length} resources, please select the worload
            </div>
            <Select
              classNamePrefix="select"
              options={workloadOptions}
              placeholder={'Workload'}
              value={workloadOptions.find((option) => option.value === formik.values.workload)}
              onChange={(option) => {
                formik.setFieldValue('workload', option.value)
              }}
            />
            {formik.touched.workload && formik.errors.workload ? (
              <div className="text-error">{formik.errors.workload}</div>
            ) : null}
          </Card.Body>
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-secondary btn-sm" onClick={() => setSelectedRows(null)}>
          Cancel
        </button>

        <button type="button" className="btn btn-primary btn-sm" onClick={save}>
          Claim
        </button>
      </Modal.Footer>
    </Modal>
  )
}
export default ClaimModal
