import React, { useContext, useEffect, useState } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable, useSortBy } from '@tanstack/react-table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons'
import getColumns from './AllowedAllocationsColumns'
import AppContext from 'context/AppContext'
import getAllAccounts from 'Admin/AccountMapperNew/use-cases/get-all-accounts'
import moment from 'moment'
import { getAccountStatus } from 'utils/reports-helper'
import useElementNames from 'hooks/useElementNames'

const currentYear = moment().year()

const AllowedAllocations = ({ accounts, actualsData, inputMonth, inputYear }) => {
  const elementNames = useElementNames()
  const [data, setData] = useState([])
  const [allAccounts, setAllAccounts] = useState([])
  const [columns, setColumns] = useState([])
  const [sorting, setSorting] = useState([])
  const { repoFactory } = useContext(AppContext)

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    initialState: {
      sorting: [
        {
          id: 'status',
          desc: false
        }
      ]
    },
    useSortBy
  })

  const getMappedData = (data) => {
    const mappedData = data.map((item) => {
      const { accountId, createdDate, startDate, endDate } = item
      let start = ''

      const dates = data
        .filter((item) => item.accountId === accountId)
        .map((item) => item.startDate)
        .sort(function (a, b) {
          return new Date(a) - new Date(b)
        })

      const createdAt = moment(createdDate)
      if (startDate === dates[0]) {
        start = createdAt.year() === currentYear ? createdAt.format('MM-DD-YYYY') : startDate
      } else {
        start = startDate
      }

      const effectiveDates = `${start} to ${endDate || 'Till-Date'}`

      return {
        ...item,
        effectiveDates,
        status: getAccountStatus(start, endDate, inputMonth, inputYear)
      }
    })

    return mappedData
  }

  useEffect(() => {
    if (actualsData) {
      const data = actualsData.map((item) => ({
        ...item,
        createdDate: allAccounts.find((el) => el.accountId === item.accountId)?.createdDate
      }))

      setData(getMappedData(data))
      setColumns(getColumns(elementNames, actualsData[0], accounts))
    }
  }, [inputMonth, actualsData, allAccounts])

  const getAccounts = () => {
    const existingAllocations = Object.values(accounts)

    getAllAccounts(
      { existingAllocations, inputYear },
      {
        accountMapperRepo: repoFactory.accountMapperRepoNew(),
        observer: {
          accountsReceived: (awsAccounts, allAccounts) => {
            setAllAccounts(allAccounts)
          },
          errorReceivingAccounts: () => {
            console.log('error')
          }
        }
      }
    )
  }

  useEffect(() => {
    getAccounts()
  }, [accounts])

  return (
    <div className="table-responsive" style={{ maxHeight: '300px' }}>
      <table className="table table-sticky-header">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id} colSpan={header.colSpan}>
                  {header.isPlaceholder ? null : (
                    <button
                      {...{
                        className: header.column.getCanSort()
                          ? 'd-flex justify-content-between align-items-center user-select-none'
                          : '',
                        onClick: header.column.getToggleSortingHandler()
                      }}
                      className="sorting-button"
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {{
                        asc: <FontAwesomeIcon className="ml-2" icon={faArrowUp} />,
                        desc: <FontAwesomeIcon className="ml-2" icon={faArrowDown} />
                      }[header.column.getIsSorted()] ?? null}
                    </button>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>
                  {cell.column.id === 'percentage' ? (
                    <>
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={(props) => {
                          return (
                            <Tooltip id="button-tooltip" {...props}>
                              {row.original.effectiveDates}
                            </Tooltip>
                          )
                        }}
                      >
                        <div>{flexRender(cell.column.columnDef.cell, cell.getContext())}</div>
                      </OverlayTrigger>
                    </>
                  ) : (
                    <>{flexRender(cell.column.columnDef.cell, cell.getContext())}</>
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default AllowedAllocations
